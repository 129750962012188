@use "colors";
@use "typography";
@import "mixins";
@import "custom";

.page-anchor-navigation {
  margin: 0;
  z-index: 60;

  @include media-breakpoint-down(md) {
    display: none;
  }

  &-static {
    nav {
      padding: 0;
    }
  }

  &-fixed {
    background-color: colors.$black;
    inline-size: 100%;
    inset-block-start: -100%;
    padding: 9px 0;
    position: absolute;
    transition: all 300ms ease-in-out;
    z-index: 20;

    @include media-breakpoint-down(md) {
      display: none;
    }

    nav {
      margin: 0 auto;
    }
  }

  &-visible {
    inset-block-start: 100%;
  }

  ul {
    margin: 0;
    padding: 0;

    a {
      @include typography.cta-small;

      color: colors.$white;
      outline: none;

      &:focus {
        span {
          border-block-end: 1px solid colors.$white;
          padding: 0 0 6px;
        }
      }
    }

    li {
      display: inline-block;
      font-size: 14px;
      line-height: 24px;
      margin-inline-end: 24px;

      &:hover,
      &.active {
        span {
          border-block-end: 1px solid colors.$white;
          padding: 0 0 6px;
        }
      }
    }
  }
}
